import { createStore } from 'vuex'

export interface State {
  currentLangId: number,
  defaultLangId: number,
  languages: any[],
  siteUrl: string
}

export default createStore<State>({
  state: {
    currentLangId: 0,
    defaultLangId: 0,
    languages: [],
    siteUrl: location.host
  },
  getters: {
    CURRENT_LANG_ID: state => { return state.currentLangId },
    DEFAULT_LANG_ID: state => { return state.defaultLangId },
    CURRENT_LANG: state => {
      for (let i = 0; i < state.languages.length; i++) {
        if (state.languages[i].id === state.currentLangId) return state.languages[i].code
      }
      return ''
    },
    LANGUAGES: state => {return state.languages},
    URL: state => {return state.siteUrl},
  },
  mutations: {
    CURRENT_LANG_ID (state, payload) {
      state.currentLangId = payload
    },
    DEFAULT_LANG_ID (state, payload) {
      state.defaultLangId = payload
    },
    LANGUAGES (state, payload) {
      state.languages = payload
    },
  },
  actions: {
    CurrentLangInitialized () {
      return new Promise(resolve => {
        let attempts = 0
        const int = setInterval(() => {
          if (this.state.currentLangId > 0) {
            clearInterval(int)
            resolve(true)
            return
          } else {
            if (attempts >= 20) {
              clearInterval(int)
              resolve(false)
            } else {
              // console.log(this.state.currentLangId)
              attempts++
            }
          }
        }, 500)
      })
    }
  },
  modules: {
  }
})
