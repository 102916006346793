
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Footer",
  methods: {
    clickOnMenu(key: string) {
      this.$router.push(`/${key}`).then(() => {
        window.scrollTo(0, 0)
      })
    }
  }

})
