
import { defineComponent, nextTick } from 'vue';
import apiService from "@/api/api";

export default defineComponent({
  name: "Header",
  data() {
    const selectItems: any[] = []
    return {
      selectItems,
      openMenu: false,
      openLangSelector: false,
      langSelectorOffset: 0,
    }
  },
  computed: {
    CURRENT_LANG_CODE(): string {
      if (!this.selectItems.length) return ''
      for (let i = 0; i < this.selectItems.length; i++) {
        if (this.$store.getters.CURRENT_LANG_ID === this.selectItems[i].id) return this.selectItems[i].code.toUpperCase()
      }
      return 'NONE'
    },
  },
  watch: {
    openLangSelector: async function() {
      if (this.openLangSelector) {
        await nextTick()
        const options: any = this.$refs['options']
        if (options) this.langSelectorOffset = options.scrollHeight
      } else {
        setTimeout(() => {
          this.langSelectorOffset = 0
        }, 200)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('CurrentLangInitialized').then((inited: boolean) => {
      if (inited) {
        this.selectItems = this.$store.getters.LANGUAGES
        // console.log('LangSel: ', JSON.stringify(this.selectItems))
      }
    })
  },
  methods: {
    getSrc(image: string): string {
      return apiService.getImageSrc(image, 'languages')
    },
    clickOnMenu(key: string) {
      if (key.indexOf('#') > -1) {
        this.$router.push('/').then(() => {
          const el = document.querySelector(key)
          if (el) el.scrollIntoView({behavior: "smooth"})
        })
      } else {
        this.$router.push(`/${key}`).then(() => {
          window.scrollTo(0, 0)
        })
      }
      this.openMenu = false
    },
    pageIs(page: string): boolean {
      return this.$route.path === '/' + page
    },
    changeLang(id: number) {
      if (!id) return
      this.$store.commit('CURRENT_LANG_ID', id)
      this.openLangSelector = false
    }
  }
})
