
import { defineComponent } from 'vue';
import GameContainer from "@/components/GameContainer.vue";
import Select from "@/components/Select.vue";
import Slider from "@/components/Slider.vue";
import deviceService from "@/services/device";
import apiService from "@/api/api";

export default defineComponent({
  name: 'Home',
  components: {GameContainer,Select,Slider},
  data() {
    const gamesTop: any[] = []
    const gamesList: any[] = []
    const selectItems: any[] = []
    return {
      gamesTop,
      gamesList,
      selectItems,
      openMenu: false,
      isMobile: deviceService.isMobile(),
      gameSection: {
        rows: 0,
        cols: 0
      },
      review: {
        gameid: 0,
        email: '',
        type: 'Other',
        comment: '',
        lang: ''
      },
      truncateSelectValue: false,
      afterReviewScreen: false,
      afterReviewScreenText: 'Thanks for your feedback!'
    }
  },
  beforeMount() {
    // console.log(deviceService.isTablet(), deviceService.isDesktop(),deviceService.isMobile())
    this.$store.dispatch('CurrentLangInitialized').then((inited: boolean) => {
      if (inited) {
        switch (true) {
          case deviceService.isMobile():
            this.gameSection.cols = 2
            break
          case deviceService.isDesktop():
            this.gameSection.cols = 6
            break
          case deviceService.isTablet():
            this.gameSection.cols = 5
        }
        apiService.getGames(this.$store.getters.URL, this.$store.getters.CURRENT_LANG_ID, deviceService.currentMarket()).then(r => {
          // console.log('GAMES: ', r)
          if (!r.data.error) {
            this.gamesTop = r.data.Games.top || []
            this.gamesList = r.data.Games.list || []
            // console.log('GAMES: ', this.gamesTop, this.gamesList)
            if (this.gamesList.length) {
              this.gameSection.rows = Math.ceil(this.gamesList.length / this.gameSection.cols)
              // console.log('GSEC', this.gamesList.length, this.gameSection.cols, this.gameSection.rows)
            }
          }
        })
        apiService.getGamesForSelect(this.$store.getters.URL, this.$store.getters.CURRENT_LANG_ID, deviceService.currentMarket()).then(r => {
          // console.log('GAMES FOR SELECT: ', r)
          if (!r.data.error && r.data.gameList) {
            for (let i = 0; i < r.data.gameList.length; i++) {
              this.selectItems.push({id: r.data.gameList[i].id, name: r.data.gameList[i].names[this.$store.getters.CURRENT_LANG_ID], icon: r.data.gameList[i].icon})
            }
            // console.log('SELECT ITEMS: ', this.selectItems)
          } else {
            console.log('ELSE')
          }
        })
      }
    })
  },
  mounted() {
    // console.log('Home Page mounted: ', this.gamesTop, this.gamesList)
  },
  methods: {
    getSrc(image: string): string {
      return apiService.getImageSrc(image, 'games')
    },
    truncateData() {
      this.review = {gameid: 0, email: '', type: '', comment: '', lang: ''}
      this.truncateSelectValue = !this.truncateSelectValue
    },
    selectReviewType(e: any) {
      if (e && e.target && e.target.value) this.review.type = e.target.value
    },
    reviewGameSelected(e: number) {
      if (e && e > 0) this.review.gameid = e
    },
    revTypeIs(str: string): boolean {
      return str === this.review.type
    },
    revTypeID(): number {
      switch (this.review.type) {
        case 'Other':
          return 2
        case 'Proposal for content change':
          return 1
        case 'Report about error':
          return 0
        default:
          return -1
      }
    },
    validateEmail() {
      var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      return re.test(String(this.review.email).toLowerCase())
    },
    sendReview() {
      if (!this.validateEmail()) {
        this.showAfterReviewScreen('Enter valid email!!!')
        return
      }
      const data = {
        gameid: this.review.gameid,
        email: this.review.email,
        type: this.revTypeID(),
        comment: this.review.comment,
        lang: this.$store.getters.CURRENT_LANG
      }
      console.log(JSON.stringify(data))
      apiService.sendReview(data).then(r => {
        // console.log('Review: ', r)
        if (!r.data.error) {
          this.truncateData()
          this.showAfterReviewScreen()
        }
      })
    },
    showAfterReviewScreen(text?: string) {
      if (text && text !== '') this.afterReviewScreenText = text
      this.afterReviewScreen = true
      setTimeout(() => {
        this.afterReviewScreen = false
        this.afterReviewScreenText = 'Thanks for your feedback!'
      }, 4000)
    },
    showMenu() {
      this.openMenu = true
    },
    hideMenu() {
      this.openMenu = false
    },
    clickOnMenu(to?: string) {
      if (!to) this.openMenu = false
    },
    toGameInfo(id: number) {
      this.$router.push('/info/' + id).then(() => {
        window.scrollTo(0, 0)
      })
    }
  }
});
