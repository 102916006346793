import * as device from 'mobile-device-detect'

function isDesktop(): boolean {
    return !device.isMobile && !device.isTablet && device.isBrowser
}

function isMobile(): boolean {
    return device.isMobile && device.isMobileOnly;
}

function isTablet(): boolean {
    return device.isMobile && !device.isMobileOnly;
}

function isAndroid(): boolean {
    return device.isAndroid
}

function isIOS(): boolean {
    return device.isIOS
}

function currentMarket(): string {
    switch (true) {
        case isDesktop():
            return 'Desktop'
        case isAndroid():
            return 'Google Play'
        default:
            return 'Apple Store'

    }
}

const deviceService = {
    isDesktop: isDesktop,
    isMobile: isMobile,
    isTablet: isTablet,
    currentMarket: currentMarket
}

export default deviceService
