
import {defineComponent} from 'vue'
import apiService from "@/api/api";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: 'App',
  components: {Header,Footer},
  beforeMount() {
    apiService.getLanguages().then(r => {
      if (!r.data.error) {
        if (r.data.languages) {
          this.$store.commit('LANGUAGES', r.data.languages)
          const browserLang = navigator.language.split('-')[0]
          console.log('BROWSER LANG: ', browserLang)
          let defaultlangId = 0
          for (let i = 0; i < r.data.languages.length; i++) {
            if (r.data.languages[i].code === browserLang) {
              console.log('FOUND: ', JSON.stringify(r.data.languages[i]))
              this.$store.commit('CURRENT_LANG_ID', r.data.languages[i].id)
              break
            } else {
              if (r.data.languages[i].is_default) {
                defaultlangId = r.data.languages[i].id
              }
              if (i === r.data.languages.length - 1) {
                this.$store.commit('CURRENT_LANG_ID', defaultlangId)
              }
            }
          }
        }
        else this.$store.commit('LANGUAGES', []);
      }
    })
  }
})

