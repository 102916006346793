export function newItem(item: any): SliderItem {
    const sliderItem: SliderItem = {image: ''}
    if (item && item.image) sliderItem.image = item.image
    if (item && item.name) sliderItem.name = item.name
    return sliderItem
}

export default interface SliderItem {
    image: string,
    name?: string
}
