import axios from "axios";

const API_URL = `https://api.console.kokids.org/`;
// const API_URL = `http://localhost:54300/`;

function getImageSrc(image: string, dir: string): string {
    if (!dir || !image) return ''
    return 'https://kokids.org/' + dir + '/' + image
}

function getApiData(path: string) {
    return axios.get(API_URL + path)
}

function postApiData(path: string, data: any) {
    return axios.post(API_URL + path, data, {responseType: 'json'})
}

function getGames(site: string, lang: string, market: string) {
    return getApiData(`for/${site}/get/games/${lang}?m=${market}`)
}

function getGameDescription(site: string, lang: string, id: number) {
    return getApiData(`for/${site}/get/game/${lang}?i=${id}`)
}

function getGamesForSelect(site: string, lang: string, market: string) {
    return getApiData(`for/${site}/get/game-list/${lang}?m=${market}`)
}

function getLanguages() {
    // if (process.env.NODE_ENV === 'production') return getApiData('get/languages')
    const formData = new FormData()
    formData.append('Key', 'obsjfdsbjtyervuibf983465+-ewb')
    return postApiData('get/languages', formData)
}

function getPage(pagename: string) {
    return getApiData(`pages/${pagename}`)
}

function sendReview(data: any) {
    return postApiData('review', data)
}

function getReviewTranslations() {
    return getApiData('translations/review')
}

function getPrintables(site: string, lang: string) {
    return getApiData(`for/${site}/get/printables/${lang}`)
}

const apiService = {
    getImageSrc: getImageSrc,
    getGames: getGames,
    getGamesForSelect: getGamesForSelect,
    getGameDescription: getGameDescription,
    getLanguages: getLanguages,
    getPage: getPage,
    sendReview: sendReview,
    getReviewTranslations: getReviewTranslations,
    getPrintables: getPrintables
}

export default apiService
