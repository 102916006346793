
import { defineComponent, PropType } from 'vue';
import apiService from "@/api/api";

export default defineComponent({
  name: "Select",
  props: {
    valueKey: {type: String, required: true},
    textKey: {type: String, required: true},
    data: {type: Array as PropType<any>, required: true},
    multiple: {type: Boolean, default: false},
    edited: {type: Boolean, default: false},
    currentValue: {type: String, default: ''},
    truncate: {type: Boolean, default: false}
  },
  data() {
    // let searchData = ''
    return {
      selectOpened: false,
      searchData: '',
      chosenValue: ''
    }
  },
  mounted() {
    console.log('New DATA ', JSON.stringify(this.data))
  },
  watch: {
    searchData: function() {
      console.log('search: ', this.searchData)
    },
    truncate: function() {
      this.chosenValue = ''
    }
  },
  methods: {
    getSrc(image: string): string {
      return apiService.getImageSrc(image, 'games')
    },
    clickOnSelect() {
      this.selectOpened = !this.selectOpened
      // this.$nextTick().then(() => {
      //   const sel: any = this.$refs[`${this.valueKey}-sel`]
      //   if (!this.selectOpened) sel.removeAttribute('style')
      //   else sel.setAttribute('style', `bottom: ${-sel.scrollHeight - 2}px`)
      // })
    },
    onSelect(value: string | number, chosenValue: string) {
      console.log('on select: ', value)
      this.chosenValue = chosenValue
      this.selectOpened = false
      this.searchData = ''
      this.$emit('selected', value)
      // console.log(JSON.stringify(this.formData))
      // if (this.formData.name != null) {
      //   let arr: string[] = this.formData.name.split(',')
      //   const index = arr.indexOf(value)
      //   if (index > -1) {
      //     arr.splice(index, 1)
      //     this.formData.name = arr.join(',')
      //   } else {
      //     if (this.formData.name !== '') this.formData.name += ','
      //     this.formData.name += value
      //   }
      // }
    },
    optionValueChosen(value: string): boolean {
      // return this.formData.name.indexOf(value) > -1
      return false
    },
    optionIsVisible(name: string): boolean {
      return this.searchData === '' ? true : name.toLowerCase().indexOf(this.searchData) > -1
    }
  }
})
