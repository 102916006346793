
import { defineComponent, PropType } from 'vue';
import SliderItem, {newItem} from "@/interfaces/slider_item";
import apiService from "@/api/api";

export default defineComponent({
  name: "Slider",
  props: {
    items: {type: Array as PropType<any>, required: true},
    delay: {type: Number, default: 1000},
    credits: {type: Boolean, default: true},
    testing: {type: Boolean, default: false},
    maxItems: {type: Boolean, default: false},
    aspectRatio: {type: Number, default: 0},
    imageKey: {type: String, default: 'games'}
  },
  data() {
    let interval: any = null
    let timeout: any = null
    const sliderItems: SliderItem[] = []
    return {
      minMultipleWidth: 992,
      slideIndex: 0,
      multipleIndex: 0,  //multiple only
      sliderMovesForward: true,
      isMultiple: false,  //multiple only
      firstAppear: true,  //multiple only
      interval,
      timeout,
      sliderItems,
    }
  },
  watch: {
    items: function() {
      console.log('Items changed: ', this.items)
      this.initSlider()
      this.$forceUpdate()
    },
  },
  computed: {
    GameName(): string {
      if (!this.items.length || !this.items[this.slideIndex]) return 'NONE'
      return this.items[this.slideIndex].name.toUpperCase()
    }
  },
  mounted() {
    // console.log('Slider mounted: ', window.innerWidth)
    this.initSlider()
    // setTimeout(() => {
    //   const elem = document.querySelector('.no-transition')
    //   if (elem) elem.classList.remove('no-transition')
    // }, 700)
  },
  methods: {
    initSlider() {
      this.isMultiple = (window.innerWidth >= this.minMultipleWidth) && this.items.length >= (!this.maxItems ? 3 : 4)
      if (this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          this.sliderItems.push(newItem(this.items[i]))
          if (i === this.items.length - 1) {
            // alert(`Slider inited; multiple - ${this.isMultiple}`)
            if (this.isMultiple) this.sliderItems = this.sliderItems.concat(this.sliderItems)
            this.$forceUpdate()
            this.startSliding()
          }
        }
      }
      // console.log('slider initialized: ', this.sliderItems)
    },
    startSliding() {
      // let firstSlide = true
      // console.log('Starting slider:', this.interval)
      if (this.interval != null) clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.switchForward()
      }, this.delay)
    },
    stopSliding() {
      // console.log('Stoping slider:', this.interval)
      // this.sliderMovesForward = false
      if (this.interval != null) clearInterval(this.interval)
    },
    //////////////////////////////////////////////FOR MULTIPLE SLIDES//////////////////////////////////////////////////
    slideIsVisible(index: number): boolean {
      if (!this.isMultiple) return index === this.slideIndex
      else {
        if (index === this.multipleIndex) return true
        else return index === this.visibleSlideIndex(1) || index === this.visibleSlideIndex(2)
            || (this.isMultiple && index === this.visibleSlideIndex(3))
      }
    },
    visibleSlideIndex(step = 0): number {
      // console.log('visible slide index: ', step)
      const index = this.slideIndex < this.multipleIndex ? this.multipleIndex : this.slideIndex
      if (!step) step = 0
      if (step <= 0) return index
      if (index + step < this.sliderItems.length - 1) return index + step
      let stepsCnt = 0
      for (let i = index; stepsCnt <= step; i++) {
        if (stepsCnt === step) return i
        if (i === this.sliderItems.length - 1) i = -1
        stepsCnt++
      }
      return 0
    },
    activeSlideOrderIs(index: number, order = 1): boolean {
      if (!order) order = 1
      if (!this.isMultiple) return false
      else return index === this.visibleSlideIndex(order - 1)
    },
    prevActiveSlide(index: number): boolean {
      const currentIndex = this.slideIndex < this.multipleIndex ? this.multipleIndex : this.slideIndex
      if (this.sliderMovesForward) {
        if(currentIndex === 0) return index === this.sliderItems.length - 1
        else return index === currentIndex - 1
      } else {
        return this.visibleSlideIndex(this.isMultiple && this.maxItems ? 4 : 3) === index
      }
    },
    nextActiveSlide(index: number): boolean {
      const currentIndex = this.slideIndex < this.multipleIndex ? this.multipleIndex : this.slideIndex
      if (!this.sliderMovesForward) {
        if(currentIndex === 0) return index === this.sliderItems.length - 1
        else return index === currentIndex - 1
      } else {
        return this.visibleSlideIndex(this.isMultiple && this.maxItems ? 4 : 3) === index
      }
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getSrc(image: string, dir: string): string {
      if (this.testing) return image
      return apiService.getImageSrc(image, dir)
    },
    gameTitle(title: string): string {
      return title.toUpperCase()
    },
    switchForward(manual?: boolean) {
      if (manual) this.stopSliding()
      let delay = 0
      if (!this.sliderMovesForward) {
        // console.log('IF ', this.sliderMovesForward, this.firstAppear)
        // delay = 1
        this.sliderMovesForward = true
        // if (this.firstAppear) this.firstAppear = false
      } else {
        // console.log('ELSE ', this.sliderMovesForward, this.firstAppear)
        if (this.firstAppear) this.firstAppear = false
      }
      setTimeout(() => {
        if (this.slideIndex === this.items.length - 1) this.slideIndex = 0
        else this.slideIndex++
        if (this.isMultiple) {
          if (this.multipleIndex === this.sliderItems.length - 1) this.multipleIndex = 0
          else this.multipleIndex++
        }
        if (manual) this.startSliding()
      }, delay)
    },
    switchBackward(manual?: boolean) {
      if (manual) this.stopSliding()
      let delay = 0
      if (manual && this.sliderMovesForward) {
        // delay = 1
        if (!this.firstAppear) this.firstAppear = true
        this.sliderMovesForward = false
        // if (this.isMultiple) this.slideIndex = this.visibleSlideIndex(1)
      }
      setTimeout(() => {
        if (this.slideIndex === 0) this.slideIndex = this.items.length - 1
        else this.slideIndex--
        if (this.isMultiple) {
          if (this.multipleIndex === 0) this.multipleIndex = this.sliderItems.length - 1
          else this.multipleIndex--
        }
        if (manual) this.startSliding()
      }, delay)
    },
    switchToIndex(index: number) {
      if (index === this.slideIndex || this.isMultiple) return
      this.stopSliding()
      let difference = index - this.slideIndex
      if (difference === 1) {
        this.switchForward(true)
        return
      } else if (difference === -1) {
        this.switchBackward(true)
        return
      } else {
        const interval = setInterval(() => {
          if (difference === 0) {
            this.startSliding()
            clearInterval(interval)
            return
          }
          if (difference > 0) {
            this.switchForward()
            difference--
          } else {
            this.switchBackward()
            difference++
          }
        }, 200)
      }
    }
  }
})

