<template>
  <div class="gm-cont" :class="{'hoverable': hoverable}">
    <img :src="image" alt="">
    <div>
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "GameContainer",
  props: {
    image: {type: String, required: true},
    title: {type: String, required: true},
    hoverable: {type: Boolean, default: false}
  }
})
</script>

<style lang="scss" scoped src="../assets/scss/gm_cont.scss"></style>
